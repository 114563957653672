import React, {
  ReactNode,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import {useRecoilState} from 'recoil';

import applyState from '@/stores/application';
import {configProps, AnswerProps} from '@/types/application.type';
import {EMAIL_REGEX, NUMBER_REGEX, SOCIAL_SECURITY_NUMBER} from '@/utils/regex';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  rightButton?: ReactNode;
  caption?: ReactNode;
  labelStyle?: React.CSSProperties;
  addClass?: string;
  type?: string;
  config?: configProps;
  index: number;
  upperQuestion?: AnswerProps;
  questionIndex?: number;
}

const calcHeight = (type: string | undefined) => {
  return type === 'LONG_TEXT' ? '200px' : '60px';
};

const getAnswerValue = (answer: any) => {
  return typeof answer === 'object' && answer !== null ? answer.value : answer;
};

function InputText({
  label,
  leftIcon,
  rightIcon,
  rightButton,
  labelStyle,
  addClass,
  type,
  config,
  index,
  upperQuestion,
  questionIndex,
  ...props
}: InputProps) {
  const [answerRegisterState, setAnswerRegisterState] = useRecoilState(
    applyState.answerRegisterState,
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [matchedRegex, setMatchedRegex] = useState<string>('');
  const [fitLength, setFitLength] = useState<string>('');

  const currentValue = upperQuestion
    ? answerRegisterState.answers
        .find(({seq}) => seq === upperQuestion.seq)
        ?.subQuestions?.find(({seq}) => seq === index)
    : answerRegisterState.answers.find(({seq}) => seq === index);

  const minHeight = calcHeight(type);

  const handleOnChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = e.target.value;
    if (
      currentValue?.config.minLength &&
      value.length < currentValue?.config.minLength
    ) {
      setFitLength(
        `* 최소 ${currentValue?.config.minLength}자 이상 입력해주세요.`,
      );
    } else {
      setFitLength('');
    }
    updateAnswer(value);
  };

  const handleOnChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      currentValue?.config.regex &&
      currentValue.config.regex === 'SOCIAL_SECURITY_NUMBER' &&
      !SOCIAL_SECURITY_NUMBER.test(e.target.value) &&
      e.target.value !== ''
    ) {
      let value = e.target.value.replace(/[^0-9]/g, '');

      if (value.length > 13) {
        value = value.slice(0, 13);
      }

      if (value.length > 6) {
        value = value.slice(0, 6) + '-' + value.slice(6);
      }

      if (value.length > 8 && !/^[1-4]/.test(value[7])) {
        value = value.slice(0, 7) + value.slice(8);
      }

      value = value.replace(/-{2,}/g, '-');

      e.target.value = value;
    }

    if (currentValue?.config.regex) {
      if (
        currentValue.config.regex === 'EMAIL' &&
        !EMAIL_REGEX.test(e.target.value)
      ) {
        setMatchedRegex('* 이메일 형식이 일치하지 않습니다.');
      } else if (
        currentValue.config.regex === 'NUMBER' &&
        !NUMBER_REGEX.test(e.target.value)
      ) {
        setMatchedRegex('* 숫자 형식이 일치하지 않습니다.');
      } else {
        setMatchedRegex('');
      }
    }

    if (
      currentValue?.config.minLength &&
      e.target.value.length < currentValue.config.minLength
    ) {
      setFitLength(
        `* 최소 ${currentValue.config.minLength}자 이상 입력해주세요.`,
      );
    } else {
      setFitLength('');
    }

    updateAnswer(e.target.value);
  };

  const updateAnswer = (value: string) => {
    setAnswerRegisterState(prev => {
      const newAnswers = prev.answers.map(item => {
        if (item.seq === (upperQuestion ? upperQuestion.seq : index)) {
          if (upperQuestion) {
            return {
              ...item,
              subQuestions: item.subQuestions
                ? item.subQuestions.map(subItem => {
                    if (subItem.seq === index) {
                      return {
                        ...subItem,
                        answer: value,
                      };
                    }
                    return subItem;
                  })
                : null,
            };
          }
          return {...item, answer: value};
        }
        return item;
      });
      return {...prev, answers: newAnswers};
    });
  };

  useEffect(() => {
    if (!answerRegisterState || questionIndex === undefined || !currentValue) {
      return;
    }

    const upperAnswerValues = upperQuestion?.answer;
    const ifConditionAnswerKeys = currentValue?.config?.ifConditionAnswerKey;

    const isConditionMatched = Array.isArray(upperAnswerValues)
      ? ifConditionAnswerKeys?.some((key: string) =>
          upperAnswerValues.find((ans: {key: string}) => ans.key === key),
        )
      : false;

    setDisabled(!isConditionMatched);

    if (!isConditionMatched) {
      updateAnswer('');
    }
  }, [answerRegisterState.answers?.[questionIndex || 0]?.answer]);

  return (
    <div className="w-full flex flex-col mb-[10px]">
      <div className="flex-col items-center bg-white">
        <div
          className={`relative w-full px-[30px] flex items-center rounded-[10px] border border-[#e5e5e5] ${addClass}`}
          style={{minHeight}}>
          {type === 'LONG_TEXT' ? (
            <textarea
              placeholder={currentValue?.config?.placeHolder || '장문형 텍스트'}
              maxLength={currentValue?.config?.maxLength}
              className={`w-full min-h-[180px] p-[10px] bg-transparent placeholder-disabled appearance-none border-none placeholder:text-[16px] focus:ring-0 text-base resize-none ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              } `}
              onChange={handleOnChangeTextArea}
              disabled={disabled}
              value={disabled ? '' : getAnswerValue(currentValue?.answer)}
            />
          ) : (
            <input
              placeholder={currentValue?.config?.placeHolder || '단답형 텍스트'}
              className={`w-full h-full bg-transparent text-[#404040] placeholder-disabled p-0 appearance-none border-none placeholder:text-[16px] focus:ring-0 text-base resize-none focus:outline-none ${
                disabled ? 'text-[#c4c4c4]' : 'text-[#404040]'
              }`}
              onChange={handleOnChangeText}
              disabled={disabled}
              value={disabled ? '' : getAnswerValue(currentValue?.answer)}
              maxLength={currentValue?.config?.maxLength}
              type="text"
              {...props}
            />
          )}
          {rightIcon}
        </div>
        {!disabled && (
          <>
            <label
              className={`${
                matchedRegex ? 'text-[#FF5353]' : 'text-primary'
              } text-xs`}>
              {matchedRegex}
            </label>
            {fitLength && (
              <label className="text-[#FF5353] text-xs">{fitLength}</label>
            )}
          </>
        )}
        <div>{rightButton}</div>
      </div>
    </div>
  );
}

export default InputText;
